import React,{useState} from "react";
import './sr.css'
import sr1 from "../assets/Victoria.jpg";
import sr2 from "../assets/sr6.png";
import sr3 from "../assets/sr7.png";
import sr4 from "../assets/sr8.jpg";
import sr5 from "../assets/sr9.png";
import sr6 from "../assets/sr10.png";

const Showrooms=()=>{
	
	 const [isVic, setIsVic] = useState(false);
	 const [isObr, setIsObr] = useState(false);
	 const [isOut, setIsOut] = useState(false);
	 const [isEp, setIsEp] = useState(false);
	 const [isCp, setIsCp] = useState(false);
     const [isIlt, setIsIlt] = useState(false);

	return (


	  <div >
	  	<div className=" grid grid-cols-12  ">
			
  		  	<div className=" col-span-6 bg-white h-10 md:h-[4.4rem]"> 	</div>
  		  	<div className=" col-span-1 mr-auto w-3/5 bg-[#0BA2FB]  h-7 md:h-[4.4rem] "> </div>
  		  	<div className=" col-span-1 w-4/5 bg-white h-10 md:h-[4.4rem]"> </div>
  		  	<div className=" col-span-2 mr-auto w-4/5 bg-[#004168]  h-7 md:h-[4.4rem] "> </div>
  		  	<div className=" col-span-1  bg-white h-10 md:h-[4.4rem]"> </div>
  		  	<div className=" col-span-1 ml-auto w-4/5 bg-[#006BA9]  h-7 md:h-[4.4rem] "> </div>
  		  	
		</div>

		<div className=" grid grid-cols-12  ">
			<div className=" col-span-12 md:col-span-6 bg-show-room2 md:bg-show-room2  bg-cover"> 
				<div className=" grid grid-rows-12  ">
					<div className=" row-span-1  "> 
						<div className=" grid grid-cols-12  ">
				  		  	<div className=" col-span-9 bg-white h-10 md:h-[4.4rem]"> 
				  		  		<h1 className="font-primary font-bold text-2xl md:text-5xl text-[#004168]  md:-mt-6 ml-12 "> Showrooms </h1>	
				  		  	</div>
				  		  	<div className=" col-span-2 mr-auto w-3/5 bg-[#006BA9]  h-7 md:h-[4.4rem] "> </div>
				  		  	<div className=" col-span-1 bg-transparent h-10 md:h-[4.4rem]"> </div>
			  		  	</div>
					</div>
					<div className=" row-span-10 bg-transparent h-[27rem] md:h-[40rem]"></div>			
					<div className=" row-span-1  ">
						<div className=" grid grid-cols-12  ">
							<div className=" col-span-1  bg-[#0BA2FB]  h-7 md:h-[4.4rem] "> </div>
				  		  	<div className=" col-span-10 bg-transparent h-10 md:h-[4.4rem]"> 	</div>
				  		  	<div className=" col-span-1 ml-auto w-3/5 bg-[#FFBB00]  h-7 mt-[1.4rem] md:h-[3rem] "> </div>
				  		</div>  	
  					</div>	  	
				</div>	
			</div>	

			<div className=" col-span-12 md:col-span-6 text-center "> 
				<div className=" grid grid-rows-12  ">
					
					<div className=" font-primary text-lg md:text-2xl row-span-3  text-center justify-center"> 
						<p className="text-[#004168] info">Visítanos en la <span className="font-bold">Sucursal Victoria en CDMX</span></p> 
						<p className="text-[#004168] ">o pide tu envío,<span className="font-bold"> ¡lo llevamos a toda la República!</span></p> 
					</div>

					<div className=" row-span-8   ">
						<div className=" grid grid-rows-2 "> 
						<div className="row-span-1">
							<div className=" grid grid-cols-3 info2 px-2 ">

								<div className=" md:col-span-1  ml-1 mr-1  md:h-[4.4rem]  ">
									<img  
									src={sr1} alt="Sucursal Victoria" className="opacity-70 hover:opacity-100 cursor-pointer" 
									  onClick={() => setIsVic(true)}/>
									  {isVic && (
        								<div className="bg-white absolute text-xl text-white posmod" onClick={() => setIsVic(false)}>
        									<div className="grid grid-cols-3 imagesr">
        										<div className="col-span-1 ">
        											<img src={sr1} alt="Sucursal Matriz Victoria" />
        										</div>
        										<div className="col-span-2  md:mt-16 ml-8 items-center text-justify text-[#004168] text-sm md:text-lg">
        											<p className="font-primary font-bold ">MATRIZ VICTORIA, CDMX</p>
        											<p className="font-primary  ">Victoria 24, Centro, Cauhtémoc, 0650, Ciudad de México</p>
        										<p className="font-primary  ">Teléfonos: 5555104697 | 5555108764</p>
        										<p className="font-primary  ">Lunes a sábados de 9:30 a 18:00</p>
        										</div>
        										<button className="modal-close" type="button" onClick={() => setIsObr(false)}>
											          X
        										</button>
        									</div>
        								</div>
      								 )}
									<p className="mt-1 font-primary font-bold text-sm text-[#004168]">Victoria, CDMX</p>
								</div>

								<div className=" col-span-1 ml-1 mr-1 "> 	
					  		  		<img  src={sr2} alt="Outlet" className="opacity-70 hover:opacity-100    "
					  		  		onClick={() => setIsOut(true)}/>
					  		  		{isOut && (
        								<div className="bg-white absolute text-xl text-white posmod3" onClick={() => setIsOut(false)}>
        									<div className="grid grid-cols-3 imagesr">
        										<div className="col-span-1 ">
        											<img src={sr2} alt="Tienda Outlet" />
        										</div>
        										<div className="col-span-2 md:mt-12 ml-8 items-center text-justify text-[#004168] text-sm md:text-lg">
        											<p className="font-primary font-bold ">OULET, CDMX</p>
        											<p className="font-primary  ">¡Encuentra eso que buscas con precios aún mejores!</p>
        											<p className="font-primary  ">Calle Aranda local D, Centro,</p>
        											<p className="font-primary  ">Cauhtémoc, 06050 </p>
        											<p className="font-primary  ">Ciudad de México</p>
        										<p className="font-primary  ">Teléfonos: 55 1857 7112</p>
        										<p className="font-primary  ">Lunes a sábados de 9:30 a 18:00</p>
        										</div>
        										<button className="modal-close" type="button" onClick={() => setIsObr(false)}>
											          X
        										</button>
        									</div>
        								</div>
      								 )}
					  		  		<p className="mt-1 font-primary font-bold text-sm text-[#004168]">Outlet, CDMX</p>
					  		  	</div>

					  		  	<div className=" col-span-1 ml-1  mr-1  md:h-[4.4rem]"> 
					  		  		<img  src={sr4}  alt="Sucursal Obrero Mundial" className="opacity-70 hover:opacity-100   "
					  		  		onClick={() => setIsObr(true)}/>	
										{isObr && (
        								<div className="bg-white absolute text-xl text-white posmod2" onClick={() => setIsObr(false)}>
        									<div className="grid grid-cols-3 imagesr">
        										<div className="col-span-1 ">
        											<img src={sr4} alt="Sucursal Obrero Mundial información" />
        										</div>
        										<div className="col-span-2 md:mt-16 ml-8 items-center text-justify text-[#004168] text-sm md:text-lg">
        											<p className="font-primary font-bold ">SUCURSAL OBRERO MUNDIAL, CDMX</p>
        											<p className="font-primary  ">Obrero Mundial 718, Antenor Sala</p>
        											<p className="font-primary  ">Benito Juárez, 03010 </p>
        										<p className="font-primary  ">Teléfonos: 55 5530 8396 |  55 5530 0802</p>
        										<p className="font-primary  ">Lunes a Viernes de 9:30 a 18:00</p>
        										<p className="font-primary  ">Sábados de 9:30 a 15:00</p>
        										</div>
        										<button className="modal-close" type="button" onClick={() => setIsObr(false)}>
											          X
        										</button>
        									</div>
        								</div>
      								 )}					  		  		
										<p className="mt-1 font-primary font-bold text-sm text-[#004168]">Obrero Mundial, CDMX</p>
					  		  	</div>
							</div>
							</div>

							<div className="row-span-1">
							<div className=" grid grid-cols-3 info3 px-2 ">

								<div className=" md:col-span-1  ml-1 mr-1  md:h-[4.4rem]  ">
									<img  
									src={sr6} alt="Sucursal Matriz Ilted" className="opacity-70 hover:opacity-100 cursor-pointer" 
									  onClick={() => setIsIlt(true)}/>
									  {isIlt && (
        								<div className="bg-white absolute text-xl text-white posmod" onClick={() => setIsIlt(false)}>
        									<div className="grid grid-cols-3 imagesr">
        										<div className="col-span-1 ">
        											<img src={sr6} alt="Tienda Matriz ILTED" />
        										</div>
        										<div className="col-span-2  md:mt-16 ml-8 items-center text-justify text-[#004168] text-sm md:text-lg">
        											<p className="font-primary font-bold ">MATRIZ ILTED, CDMX</p>
        											<p className="font-primary  ">Victoria 24 int. 11, Centro, Cauhtémoc, 0650, Ciudad de México</p>
        										<p className="font-primary  ">Teléfonos: 55 5510 3403</p>
        										<p className="font-primary  ">Lunes a sábados de 9:30 a 18:00</p>
        										</div>
        										<button className="modal-close" type="button" onClick={() => setIsObr(false)}>
											          X
        										</button>
        									</div>
        								</div>
      								 )}
									<p className="mt-1 font-primary font-bold text-sm text-[#004168]">Ilted, CDMX</p>
								</div>
							<div className=" col-span-1 ml-1  mr-1  md:h-[4.4rem] ">
					  		  		<img  src={sr3} alt="Sucursal Everardo" className="opacity-70 hover:opacity-100    "
					  		  		onClick={() => setIsEp(true)}/>
					  		  		{isEp && (
        								<div className="bg-white absolute text-xl text-white posmod3" onClick={() => setIsEp(false)}>
        									<div className="grid grid-cols-3 imagesr">
        										<div className="col-span-1 ">
        											<img src={sr3} alt="Tienda Everardo" />
        										</div>
        										<div className="col-span-2 md:mt-16 ml-8 items-center text-justify text-[#004168] text-sm md:text-lg">
        											<p className="font-primary font-bold ">SUCURSAL EVERARDO, PACHUCA</p>
        											<p className="font-primary  ">Blvd. Everardo Márquez 424, local 15,</p>
        											<p className="font-primary  ">Lomas Residencial Pachuca, 42094, </p>
        											<p className="font-primary  ">Pachuca, Hidalgo </p>
        										<p className="font-primary  ">Teléfonos: (771) 1073 327 <p> (771) 1532 464</p></p>
        										<p className="font-primary  ">Lunes a Viernes de 9:30 a 18:00</p>
        										<p className="font-primary  ">Sábados de 9:30 a 15:00</p>
        										</div>
        										<button className="modal-close" type="button" onClick={() => setIsObr(false)}>
											          X
        										</button>
        									</div>
        								</div>
      								 )}		
					  		  		<p className="mt-1 font-primary font-bold text-sm text-[#004168]">Everardo, Pachuca</p>
					  		  	</div>

					  		  	<div className=" col-span-1 ml-1 mr-1  md:h-[4.4rem] ">
					  		  		<img  src={sr5} alt="Sucursal Colosio" className=" opacity-70 hover:opacity-100  "
					  		  		onClick={() => setIsCp(true)}/>
					  		  		{isCp && (
        								<div className="bg-white absolute text-xl text-white posmod2" onClick={() => setIsCp(false)}>
        									<div className="grid grid-cols-3 imagesr">
        										<div className="col-span-1 ">
        											<img src={sr5} alt="Tienda Colosio" />
        										</div>
        										<div className="col-span-2 md:mt-16 ml-8 items-center text-justify text-[#004168] text-sm md:text-lg">
        											<p className="font-primary font-bold ">SUCURSAL COLOSIO, PACHUCA</p>
        											<p className="font-primary  ">Plaza Valentina,</p>
        											<p className="font-primary  ">Blvd. Luis Donaldo Colosio S/N,</p>
        											<p className="font-primary  ">Ex Hacienda de Coscotitlán, 42080, </p>
        											<p className="font-primary  ">Pachuca, Hidalgo </p>
        										<p className="font-primary  ">Teléfonos: (771) 7104 438 </p>
        										<p className="font-primary  ">Lunes a Viernes de 9:30 a 18:00</p>
        										<p className="font-primary  ">Sábados de 9:30 a 15:00</p>
        										</div>
        										<button className="modal-close" type="button" onClick={() => setIsObr(false)}>
											          X
        										</button>
        									</div>
        								</div>
      								 )}		
					  		  		<p className="mt-1 font-primary font-bold text-sm text-[#004168]">Colosio, Pachuca</p>
					  		  	</div>
							</div>
							</div>

				  		</div>
				  		
				  	</div>	
					<div className=" row-span-1  ">
						<div className=" grid grid-cols-12  ">
							<div className=" col-span-1 mr-auto w-3/5 bg-[#FFBB00] md:mt-[1.4rem] md:h-[3rem] "> </div>
				  		  	<div className=" col-span-10 bg-transparent  md:h-[4.4rem]"> 	</div>
				  		  	<div className=" col-span-1  bg-[#0BA2FB]  h-7  md:h-[4.4rem] "> </div>
				  		</div>  	
  					</div>	  	
				</div>	
			</div>	
				
			
  		  	
		</div>

		

		
		
    </div>		

  );
};

export default Showrooms;

 