import React from "react";
import {FaWhatsapp} from 'react-icons/fa'
import "./SocialIcons.css"
const SocialIcons=()=>{
	return(
	<div className="flex flex-col invisible md:visible md:top-[78%] left-0 fixed">
		<ul>
			<li className="flex justify-between items-center w-40 h-14 px-3 bg-[#006BA9]  bg-opacity-90 ml-[-115px] md:ml-[-102px] hover:ml-[-5px] hover:rounded-md">
				<a href="https://api.whatsapp.com/send?phone=525517942504&text=Hola! Me gustaría saber más de sus productos" target="_blank" rel="noopener noreferrer" className="flex justify-between items-center w-full text-white">
				Contáctanos<FaWhatsapp  className="ml-2 tamaño"/></a>
			</li>
			
		</ul>
	</div>
	)
};

export default SocialIcons;

