import Encabezado from "./components/Encabezado"
import Pie from "./components/Pie"
import Inicio from "./components/Inicio"
import SocialIcons from "./components/SocialIcons"
import {ScrollToTop} from "./components/ScrollTop"
import Contacto from "./components/Contacto"
import BeginTop from "./components/BeginTop"
import Aviso from "./components/Aviso"
import Nosotros from "./components/Nosotros"
import BestSellers from "./components/BestSellers"
import Marcas from "./components/Marcas"
import Catalogos from "./components/Catalogos"
import Showrooms from "./components/Showrooms"
import Mensaje from "./components/Mensaje"
import {BrowserRouter as Router, Routes,  Route} from "react-router-dom";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-WWBTD8S'
}

TagManager.initialize(tagManagerArgs)
function App() {
  return (
    <Router>
    <BeginTop/>

      <div className="App">
         
          <header className="-mb-4">
          <Encabezado className="" />
          </header>
                <Routes>
          <Route exact path="/" element={<Inicio />}/>
          <Route path="/Nosotros" element={<Nosotros/>}/>
          <Route path="/AvisodePrivacidad" element={<Aviso/>}/>
          <Route path="/Contacto" element={<Contacto/>}/>
          <Route path="/BestSellers" element={<BestSellers/>}/>
          <Route path="/Marcas" element={<Marcas/>}/>
          <Route path="/Showrooms" element={<Showrooms/>}/>
          <Route path="/Catálogos" element={<Catalogos/>}/>
          <Route path="/Aviso" element={<Mensaje/>}/>
        </Routes>
        <Pie/>
        <SocialIcons/>
        <ScrollToTop/>
      </div>
    </Router> 
  );
}

export default App;
