import React from "react";
import {Link} from "react-router-dom";

const Pie=()=>{
  return(

    <footer className="bg-neutral-100 text-center dark:bg-neutral-600 justify-center lg:text-left">
      <div className="container p-6 ">
      <hr class="h-px mb-6 ml-12 md:ml-20  md:mr-20 bg-gray-400 border-0 dark:bg-gray-600"/>
        <div className="grid md:grid-cols-2 lg:grid-cols-2">
          
          <div className="justify-center text-center md:ml-32 ">
            <h5
              className="text-center md:text-left  md:ml-20 pb-4  font-bold  text-gray-600 dark:text-neutral-200">
              Síguenos en
            </h5>

            <div className="flex ml-12 md:ml-0  ">
            <a href="https://api.whatsapp.com/send?phone=525517942504&text=Hola! Me gustaría saber más de sus productos" target="_blank" rel="noopener noreferrer" className="mr-6 text-neutral-600 dark:text-neutral-200">
            <svg xmlns="http://www.w3.org/2000/svg" 
            fill="currentColor" 
            className="h-7 w-7" 
            viewBox="0 0 16 16"> <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/> </svg>
          </a>
            <a href="https://www.facebook.com/selcailuminacion/" target="_blank" rel="noopener noreferrer" className="mr-6 text-neutral-600 dark:text-neutral-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-7 w-7"
              fill="currentColor"
              viewBox="0 0 24 24">
              <path
                d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
            </svg>
          </a>
        
            <a href="https://www.instagram.com/selcailuminacionmx/" target="_blank" rel="noopener noreferrer" className=" mr-6 text-neutral-600 dark:text-neutral-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-7 w-7"
              fill="currentColor"
              viewBox="0 0 24 24"> <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
          </a>

          <a href="https://pin.it/7GDcdR7" target="_blank" rel="noopener noreferrer" className="mr-6 text-neutral-600 dark:text-neutral-200">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-8 w-8" 
              viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"/> <line x1="8" y1="20" x2="12" y2="11" /> <path d="M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7" /> <circle cx="12" cy="12" r="9" /> </svg>
          </a>
          <a href="https://twitter.com/gruposelca?t=Heq4bgWP3ADlfkFbMj0I4w&s=09" target="_blank" rel="noopener noreferrer" className="mr-6 text-neutral-600 dark:text-neutral-200">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" viewBox="0 0 24 24">
            <path fill="currentColor" d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23Z"/></svg>
          </a>
          
        </div>
          </div>

         
                  
          <div className="md:mb-6 text-center md:text-right mt-6">
            <h5
              className="md:mb-2.5 md:mr-40 font-bold text-gray-600 dark:text-neutral-200"><a href="mailto:selca@selcailuminacion.com">
              selca@selcailuminacion.com
            </a> </h5>

            
          </div>

         
          
        </div>
      </div>

      
      <div
        className="bg-neutral-200 p-4 text-center text-neutral-600 dark:bg-neutral-500 dark:text-neutral-200 font-bold">
        Grupo Selca 2023  | |
        <span className="text-neutral-600 dark:text-neutral-200 font-bold">
           <Link to="/AvisodePrivacidad"> Aviso de privacidad</Link></span>
      </div>
    </footer>
    );
};

export default Pie;