import React from "react"

const Aviso=()=>{
	
	return (
	 <div id="AvisodePrivacidad" className=" m-auto w-10/12 md:w-5/6">
		<h2 className="font-bold pt-6 mb-4 text-2xl">Responsable de la protección de sus datos personales (Identidad y domicilio)</h2>
		<p className="text-justify mb-2 font-primary">SELCA Iluminación, S.A. de C.V. (en lo sucesivo “SELCA”), con domicilio ubicado en 
		 Victoria número 24, Colonia, Centro, Delegación Cuauhtémoc, código postal 06050, México Distrito Federal es responsable del 
		 tratamiento de sus datos personales. De conformidad con lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los 
		 Particulares, (en lo sucesivo la “Ley”), SELCA cuenta con todas las medidas de seguridad físicas, técnicas y administrativas 
		 adecuadas para proteger sus datos personales e impedir que terceros no autorizados accedan a los mismos. 
		 Bajo ninguna circunstancia comercializaremos sus datos personales sin su consentimiento previo y por escrito.</p>

		<h2 className="font-bold pt-4 mb-4 text-2xl">¿Para qué fines recabamos y utilizamos sus datos personales? (finalidades)</h2>
		<p className="text-justify mb-2 font-primary">Sus datos personales serán utilizados para las siguientes finalidades:</p>
		
		<div class="grid grid-cols-1 md:grid-cols-3 gap-4">
  			<div className="md:mr-2">
  				<h2 className="font-primary pt-6 mb-4 text-xl">Si usted es <span className="font-bold">Cliente</span> para:</h2>
				<p className="text-justify mb-2 font-primary">
				<ul class="list-disc ml-8">
				<li>Proveer los servicios y/o productos relacionados con sistemas de iluminación que nuestra empresa comercializa,
				 ya sea de marcas propias de la empresa o de sus filiales. </li>
				<li>Informarle sobre cambios en la línea de productos, precios, disponibilidad y condiciones de pago de los mismos.</li> 
				<li>Envío de información por medios electrónicos acerca de los productos comercializados por Selca o cualquier integrante 
				del grupo. </li>
				<li>Prestar servicios de atención al cliente.</li>
				<li>En caso de créditos para determinar su solvencia crediticia y capacidad de pago.</li>
				</ul>
				</p>
  			</div>
  			<div className="md:ml-2 md:mr-2">
  				<h2 className="font-primary pt-6 mb-4 text-xl">Si usted es <span className="font-bold">Proveedor</span> para:</h2>
				<p className="text-justify mb-2 mr-2 font-primary">
				<ul class="list-disc ml-8">
					<li>Realizar consultas acerca de los servicios y productos relacionados con sistemas de iluminación que Selca
					 comercializa.</li>
					<li>Obtener cotizaciones y efectuar pagos.</li>
					<li>Enviar pedidos.</li>
					<li>Responder sobre ofertas.</li>
					<li>Verificación de sus datos y obtención de referencias comerciales.</li>
				</ul>
				</p>
  			</div>
  			<div className="md:ml-2 ">
  				<h2 className="font-primary pt-6 mb-4 text-xl">Si usted es <span className="font-bold">Candidato/Colaborador</span> para:</h2>
				<p className="text-justify ml-2 mb-2 font-primary">
				<ul class="list-disc ml-8">
					<li>Verificar antecedentes laborales y académicos.</li>
					<li>Actividades de reclutamiento, selección y contratación de personal.</li>
					<li>Dar cumplimiento a lo previsto en los contratos de trabajo y su administración con motivo de la vigencia de la relación
					 laboral.</li>
					<li>Integración y actualización de expedientes.</li>
					<li>Dar cumplimiento a obligaciones de carácter fiscal y laboral.</li>
					<li>Otorgamiento de estímulos y reconocimientos.</li>
					<li>Análisis y elaboración de estadísticos de carácter interno.</li>
				</ul>
				</p>
  			</div>
	    </div>

		<h2 className="font-bold pt-4 mb-4 text-2xl">Finalidades no Necesarias (finalidades secundarias)</h2>
		<p className="text-justify mb-2 font-primary">Asimismo, al proporcionar sus datos, usted autoriza a SELCA, su utilización con fines
		 mercadotécnicos, estadísticos, promocionales, publicitarios, informativos o de prospección comercial respecto a las actividades y 
		 productos comercializados por SELCA sus filiales, subsidiarias y/o socios de negocios o relacionados con dichos bienes y servicios, 
		 sin que estas últimas sean finalidades necesarias ni finalidades que den origen a la relación jurídica entre usted e SELCA.</p>

		<h2 className="font-bold pt-4 mb-4 text-2xl">¿Qué datos personales obtenemos y de dónde? (datos personales tratados)</h2>
		<p className="text-justify mb-2 font-primary">Para las finalidades señaladas en el presente aviso de privacidad, podemos recabar sus 
		datos personales de distintas formas: cuando usted nos los proporciona directamente; cuando visita nuestro sitio de internet o 
		utiliza nuestros servicios en línea, y cuando obtenemos información a través de otras fuentes públicas permitidas por la Ley.</p>
	 </div>			

  );
};

export default Aviso;

 