import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";
import {  itemcatalogo } from "./datos.jsx";



const Carruselcatalogo = () => {
  
  const settingscat = {
  dots: true,
  infinite: true,
  speed: 500,
  rows: 1,
  slidesToShow: 4.7,
  slidesToScroll: 4.73,
  autoplay: true,
  autoplaySpeed: 2500,
  variableWidth: false,
  responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 4,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.85,
            slidesToScroll: 1.8385,
            dots: false,
            infinite:true
          }
        }
      ]

};

  return (
    <div className=" cuerpocmcat">

      <div className="containercmcat" >
        <Slider {...settingscat}>
        {itemcatalogo.map((imageUrl, index) => {
          return (
            <div className="" key={index}>
            <a href={imageUrl.ref} target="_blank" rel="noopener noreferrer" className=" text-white  " >
              <img src={imageUrl.url} alt={imageUrl.alt} width="100%" height="100%"   className="  md:ml-2 scale-[.8] md:scale-[.82] opacity-80 hover:opacity-100 hover:scale-[1] rounded-lg hover:rounded-md" />
            </a>
            </div>
            
          );
        })}
       </Slider>
      </div>
    </div>
  );
};
export default Carruselcatalogo;
