import React from "react"
import {Link} from "react-router-dom";

const Mensaje=()=>{
	
	return (
	 <div >
	 <div className=" grid grid-cols-12  ">
			<div className=" col-span-2  bg-white h-10 md:h-[4.4rem]"> </div>
  		  	<div className=" col-span-1 ml-auto w-1/2 bg-[#004168]  h-9.5 md:h-[4.5rem]"> </div>
  		  	<div className=" col-span-2  bg-white h-10 md:h-[4.4rem]"> </div>
  		  	<div className=" col-span-2  ml-auto w-4/5 bg-[#006BA9]  h-9.5 md:h-[4.5rem]"> </div>
  		  	<div className=" col-span-2  bg-white h-10 md:h-[4.4rem]"> </div>
  		  	<div className=" col-span-2  ml-auto w-4/5 bg-[#0BA2FB]   h-9.5 md:h-[4.5rem] "> </div>
  		  	<div className=" col-span-1   bg-white h-10 md:h-[4.4rem]"> </div>
		</div>
	 	<div className="pt-[100px] pb-[200px] px-8">
			<p className="md:text-3xl text-[#004168]">Hemos recibido tu mensaje.</p>
			<p className="md:text-2xl mt-4 mb-2">En breve nos comunicaremos contigo.</p>
			<button className=" md:py-2 mt-2 md:mt-4 px-6 group bg-[#0BA2FB] bg-opacity-90  
       text-sm md:text-xl font-bold text-white w-fit flex items-center rounded-md">
       <Link to="/">Regresar</Link>
       </button>
		</div>
		
		
	 </div>			

  );
};

export default Mensaje;

 