import marca   from "../assets/marca.png";
import marca1  from "../assets/marca1.png";
import marca2  from "../assets/marca2.png";
import marca3  from "../assets/marca3.png";
import marca4  from "../assets/marca4.png";
import marca5  from "../assets/marca5.png";
import marca6  from "../assets/marca6.png";
import marca7  from "../assets/marca7.png";
import marca8  from "../assets/marca8.png";
import marca9  from "../assets/marca9.png";
import marca10 from "../assets/marca10.png";
import marca11 from "../assets/marca11.png";
import marca12 from "../assets/marca12.png";
import marca13 from "../assets/marca13.png";
import marca14 from "../assets/marca14.png";
import marca15 from "../assets/marca15.png";
import marca16 from "../assets/marca16.png";
import marca17 from "../assets/marca17.png";
import marca18 from "../assets/marca18.png";
import marca19 from "../assets/marca19.png";
// import marca20  from "../assets/marca20.png";

export const itemmarca = [
  
  {   id:1, url:marca,      alt:"Aksi"   },   {  id:4,  url:marca1, alt:"Astro"     },  { id:7,   url:marca2, alt:"Quor"  },  {  id:10,  url:marca3, alt:"Osram"     },
  {   id:2, url:marca4,  alt:"Phillips"  },   {  id:5,  url:marca5, alt:"Tecnolite" },  { id:8, url:marca6, alt:"Lutron"  },  {  id:11,  url:marca7, alt:"Eglo"     },
  {   id:3, url:marca8,       alt:"Grok" },   {  id:6,  url:marca9, alt:"LedsC4"    },  { id:9, url:marca10, alt:"Luxica" },  {  id:12,  url:marca11, alt:"Vimar"    },
  {   id:13, url:marca12,alt:"Artilite"  },   {  id:14,  url:marca13, alt:"Ilux"    },  { id:15, url:marca14, alt:"Maxxi" },  {  id:16,  url:marca15, alt:"Megamex"    },
  {   id:17, url:marca16,   alt:"Calux"  },   {  id:18,  url:marca17, alt:"Calux"   },  { id:19, url:marca18, alt:"Auro"  },  {  id:20,  url:marca19, alt:"Estevez"    }
];