import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./marca.css";
import { itemmarca  } from "./itemmarcas.jsx";

const Carruselmarca = () => {

  const settingscat = {
  dots: true,
  infinite:true,
  slidesToShow:4.7,
  speed: 500,
  rows: 1,
  slidesToScroll: 4.7,
  autoplay: true,
  autoplaySpeed: 2500,
  variableWidth: false,
  responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3.6,
            slidesToScroll: 3.8,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            dots:false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.85,
            slidesToScroll: 1.81,
            dots:false,
            infinite:true
          }
        }
      ]

};

  return (
    <div className="cuerpocmin">
      <div className="containercmin ">
        <Slider {...settingscat}>

          {itemmarca.map((imageUrl, index) => {
            return (
              <div className=" mt-4 md:mt-0 mb-4 " key={index}>
                <img src={imageUrl.url} alt={imageUrl.alt} width="100%" height="100%"  className=" ml-2 scale-[.71] md:scale-[.83]  hover:shadow-2xl  hover:scale-100 ease-in duration-100" />
              </div>
              
            );
          })}
        </Slider>
      </div>
    </div>
  );
};
export default Carruselmarca;
