import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { itemmarca  } from "./itemmarcas.jsx";
import "./estilobs2.css";

const settings = {
      
      dots: true,
      autoplay: true,
      autoplaySpeed: 3000,
      infinite: false,
      slidesToShow: 2,
     slidesToScroll: 1.9,
      speed: 500,
      rows: 2,
      slidesPerRow: 2,
      centermode:true,
      centerPadding: 30
    };

const Carruselmarca2 = () => {


  return (
    <div className="cuerpocm">
      <div className="slick-wrappercm">

      <Slider {...settings}>
          {itemmarca.map((item) => (
            <div key={item.id} className="margincm">
              <img src={item.url} alt={item.alt} className=" ml-4 md:scale-[.77] imagencm " />
            </div>
      

          ))}
        </Slider>
        </div>
    </div>
  );
};
export default Carruselmarca2;

