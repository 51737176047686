import React, {useState} from "react"
import '../index.css';
import logo from "../assets/LogoSelca.png";
import {FaBars, FaTimes} from "react-icons/fa"
import "./Encabezado.css"
/*import Fade from 'react-reveal/Fade';*/

const Encabezado=()=>{

  const [nav, setNav]=useState(false);

  const links =[
    { id:1,
      link:'Inicio',
      ref:"/",
      nombreicon:"Inicio",
      child:(
        <div className="flex justify-left">Inicio </div>
        )   
    },
    { id:2,
      link:'Best Sellers',
      ref:"/BestSellers",
      nombreicon:"Best Sellers",
      child:(
        <div className="flex justify-left">  Best Sellers</div>
        )
    },
    { id:5,
      link:'Catálogos',
      ref:"/Catálogos",
      nombreicon:"Catálogos",
      child:(
        <div className="flex justify-left">  Catálogos</div>
        )
    },

    { id:3,
      link:'Marcas',
      ref:"/Marcas",
      nombreicon:"Marcas",
      child:(
        <div className="flex justify-left"> Marcas </div>
        )
    },
    { id:4,
      link:'Nosotros',
      ref:"/Nosotros",
      nombreicon:"Nosotros",
      child:(
        <div className="flex justify-left">Nosotros  </div>
        )   
    },
    
    
    
    { id:6,
      link:'Showrooms',
      ref:"/Showrooms",
      nombreicon:"Showrooms",
      child:(
        <div className="flex justify-left">  Showrooms</div>
        )
    },
    { id:7,
      link:'Contacto',
      ref:"/Contacto",
      nombreicon:"Contacto",
      child:(
        <div className="flex justify-left">  Contacto</div>
        )
    },
  ]

  
  return( 
   <nav className=" navbar sticky  w-full grid grid-rows-2  posnav">
    
      <div className="text-white text-bold  justify-between text-center pt-1 bg-[#0BA2FB] font-primary   h-12">
        ¡Envíos a toda la República!
      </div>
      
    <div className="flex bg-white px-4 justify-between items-center w-full top-0 margensup  ">
      <div className="flex justify-center px-4 align-middle items-center md:w-1/6 md:ml-24 ">
      <img   src={logo} alt="Logo Fcap" className="logoselca "/>
      </div>

      <ul className="hidden md:flex md:w-5/6 justify-center  ">
        {links.map(({id,link,ref,nombreicon})=>(
          <li key={id}  href={ref} className=" font-primary px-4 mr-12 cursor-pointer capitalize font-bold text-[#006BA9]  hover:scale-100 duration-200"> 
          <a href={ref} aria-label={nombreicon} className="hover:color-yellow-400">{link}</a></li>
          ))}
      </ul>


      <div onClick={()=>setNav(!nav)} className="cursor-pointer text-[#004168] md:hidden ">
        {nav ? <FaTimes size={23}/> : <FaBars size={23}/>}
      </div>

      {nav && (
        
        <ul onClick={()=>setNav(!nav)} className=" flex flex-col ml-48 justify-center opacity-90 items-left absolute top-0 right-0  w-36 h-76 bg-white">
        {links.map(({id,link,ref,nombreicon,child})=>(
          <li key={id}  className="px-4  mt-2  cursor-pointer capitalize font-medium text-[#0BA2FB] "> 
          <a href={ref} aria-label={nombreicon} className="hover:color-yellow-400">{child}</a></li>
          ))}
        
        
        </ul>
        
      )}
      
    </div>
    
   </nav>
  );
};

export default Encabezado;