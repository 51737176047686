import React from "react";
import Carruselmarca2 from "./Carruselmarca2"

const Marcas=()=>{
	return (


	  <div >
	  	<div className=" grid grid-cols-12  ">
			
  		  	<div className=" col-span-3 bg-white h-10 md:h-[4.4rem]"> 	</div>
  		  	<div className=" col-span-2 ml-auto w-4/5 bg-[#006BA9]  h-7 md:h-[4.4rem] "> </div>
  		  	<div className=" col-span-2 bg-white h-10 md:h-[4.4rem]"> </div>
  		  	<div className=" col-span-1 ml-auto w-4/5 bg-[#004168]  h-7 md:h-[4.4rem] "> </div>
  		  	<div className=" col-span-1  bg-white h-10 md:h-[4.4rem]"> </div>
  		  	<div className=" col-span-2 ml-auto w-4/5 bg-[#0BA2FB]  h-7 md:h-[4.4rem] "> </div>
  		  	<div className=" col-span-1  bg-white h-10 md:h-[4.4rem]"> </div>
		</div>

		<div className=" grid grid-cols-12  ">
			<div className=" col-span-1  bg-white h-10 md:h-[4.4rem]"> </div>
  		  	<div className=" col-span-10">
  		  		<h1 className="font-primary font-bold ml-4 md:ml-0 text-2xl md:text-4xl text-[#004168]  "> Marcas</h1>
  		  	</div>
   		  	<div className=" col-span-1 md:col-span-1  bg-[#006BA9] h-10 md:h-[4.4rem]"> </div>
		</div>

		<div className=" grid grid-cols-12  ">
			<div className=" col-span-1 md:col-span-1  mr-auto w-4/5 bg-[#FFBB00]  h-7 md:h-[8rem]"> </div>
  		  	<div className=" ml-6 md:ml-0 col-span-9 md:col-span-4 font-primary mb-2 ">
	  		  	<p className=" md:ml-20 mt-4 md:mt-20 text-[#004168] text-sm md:text-lg"> Contamos con marcas reconocidas y los mejores diseños para iluminar
	  		  	cualquier espacio.</p>
	  		  	<p className=" md:ml-20 mt-2 text-[#004168] text-sm md:text-lg">Además tenemos un amplio stock,</p>
	  		  	<p className="md:ml-20 font-bold mt-2 text-[#004168] text-sm md:text-lg">¿buscas un producto? ¡Llévatelo enseguida!</p>
	  		  	
  		  	</div>
  		  	<div className=" col-span-10 md:col-span-7 font-primary  md:mt-10   md:-ml-4">
  		  		<Carruselmarca2/>
  		  	</div>
  		  	
		</div>

		<div className=" grid grid-cols-12  ">	
			<div className=" col-span-1  bg-[#006BA9]  h-7 md:h-[4.4rem] "> </div>
  		  	<div className=" col-span-11 bg-white h-10 md:h-[4.4rem]"> 	</div>  	
		</div>

		<div className=" grid grid-cols-12  ">	
			<div className=" col-span-1 mr-auto w-4/5 bg-white  h-7 md:h-[4.4rem] "> </div>
  		  	<div className=" col-span-2 bg-[#006BA9] h-10 md:h-[4.4rem]"> 	</div>  
  		  	<div className=" col-span-3 bg-white  h-7 md:h-[4.4rem] "> </div>	
  		  	<div className=" col-span-2 ml-auto w-4/5 bg-[#0BA2FB] h-10 md:h-[4.4rem]"> 	</div>  
  		  	<div className=" col-span-2 mr-auto w-4/5 bg-[#FFBB00]  h-10 md:h-[4.4rem]"> 	</div> 
  		  	<div className=" col-span-1 mr-auto w-4/5 bg-white  h-7 md:h-[4.4rem] "> </div>
  		  	<div className=" col-span-1 mr-auto w-4/5 bg-[#006BA9]  h-7 md:h-[4.4rem] "> </div> 
		</div>
		
    </div>		

  );
};

export default Marcas;

 