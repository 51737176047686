import React from "react"
import {Link} from "react-router-dom";
import pr1 from "../assets/pr1.png";
import pr3 from "../assets/pr3.png";
import pr2 from "../assets/pr2.png";
import pr4 from "../assets/pr4.png";
import pr5 from "../assets/pr5.png";
import bs from "../assets/bs.png";
import bs1 from "../assets/bs1.jpg";
import bs2 from "../assets/bs2.jpg";
import nos from "../assets/texto1.png";
import vic from "../assets/Victoria.jpg";
import info from "../assets/Info.jpg";

import Carruselcatalogo from "./Carruselcatalogo"
import Carruselmarca from "./Carruselmarca"

import videopresentacion from "../assets/selcavideo.mp4";
import "./Inicio.css"
const Inicio=()=>{
	
	return (
	 <div className="" >
	 	<div className="w-full md:h-screen overflow-hidden  " >
	 	<div className="vo textpos">
	 	 <p className="font-primary font-bold text-[#004168]">Donde todo</p><p className="font-primary font-bold text-[#004168] -mt-3" > se ilumina</p>
	 	 </div>
	 	 <div dangerouslySetInnerHTML={{
            __html: `<video className="" autoPlay muted loop playsInline ><source src=${videopresentacion} type="video/mp4"/> </video>`,
          }} />
	 	 
	 	
	 	</div>

		<div className=" grid grid-cols-8  ">
  		    <div className=" col-span-1 bg-[#006BA9] "> </div>
  			<div className="col-span-2 text-center m-auto font-primary font-bold  md:text-3xl text-[#004168] textot">Best Sellers</div>
  			<div className="col-span-1 opacity-60 hover:opacity-100">
  				<img width="220" height="120" src={bs1} alt="Decoración 1" className="   "/>
  			</div>
  			<div className="col-span-3   bg-gradient-to-r from-[#0BA2FB] 20% via-[#006BA9] 30% to-[#004168] 90%">
  				<img width="320" height="120" src={bs} alt="Best Seller 1" className="m-auto pic2"/>
  			</div>
  			<div className="col-span-1 opacity-60 hover:opacity-100">
  				<img width="220" height="100" src={bs2} alt="Decoración 2" className="   "/>
  			</div>		
  			
		</div>

		<div className=" grid grid-cols-8 ">
		 
  		    <div className=" col-span-3 bg-[#004168] ">
  				<img width="320" height="120" src={bs} alt="Best Seller 2" className="m-auto pic2"/>
  			</div>
  			<div className=" col-span-2  text-center m-auto font-primary text-white textob ">
  				<button class="bg-[#004168] hover:bg-[#006BA9] text-white py-2 px-2 md:px-4 rounded-full textb">
  					<Link to="/BestSellers"> Descubre más</Link>
				</button>
  			</div>
  			<div className=" col-span-1 bg-[#006BA9] "> 
  				<img width="100" height="120" src={pr2} alt="Best Seller 3" className=" pic3 pic4"/>
  			</div>
  			<div className="col-span-2    bg-white">
  				<img width="280" height="120" src={pr4} alt="Best Seller 4" className="m-auto pic2 pic7"/>
  			</div>			
  		</div>

		<div className=" grid grid-cols-8 ">
  		    <div className=" col-span-1 bg-[#0BA2FB] "> </div>
  		    <div className="col-span-1 opacity-60 hover:opacity-100">
  				<img width="220" height="120" src={pr1} alt="Decoración 3" className="   "/>
  			</div>
  			<div className=" col-span-1 bg-white "> </div>
  			<div className=" col-span-1 bg-[#006BA9] "> </div>
  			<div className=" col-span-1 bg-[#FFBB00] "> </div>
  			<div className=" col-span-1 bg-[#004168] "> 
  			<img width="100" height="120" src={pr3} alt="Best Seller 5" className="m-auto pic3"/>
  			</div>
  			<div className="col-span-2    bg-white">
  				<img width="280" height="120" src={pr5} alt="Best Seller 6" className="m-auto pic2 pic6"/>
  			</div>
  	    </div>

		<div className=" grid grid-cols-11 mt-10 mb-12 "> 
  			<div className="col-span-2 bg-[#004168] text-center md:text-3xl mt-2 py-10 lg:py-24 text-white textot font-primary">
  			<p className=" mt-10  lg:mt-6"><Link to="/Catálogos"> Catálogos</Link></p></div>
  			<div className="col-span-9 mt-2 md:mt-6">
  				<Carruselcatalogo/>
  			</div> 		
		</div>

		<div className=" grid grid-cols-8 mb-10 "> 
		<div className="col-span-6 mt-3 md:mt-5 ml-4">
  				<Carruselmarca />
  			</div> 
  			<div className="col-span-2 bg-[#0BA2FB] md:ml-0.5 text-center text-xl md:text-3xl md:my-2 py-8 md:py-12 font-primary text-white textot">
  			<div className=" md:w-1/2 md:m-auto  leading-[1rem] mt-4 md:mt-0 md:leading-[3rem] "><Link to="/Marcas">Nuestras Marcas</Link></div></div>
  					
		</div>

		<div className="relative  ">  
  			<div className="  w-full ">
  				<img  src={nos} alt="Decoración Iluminación" className="m-auto "/>
  				<button class="absolute top-0 font-primary text-white px-2 md:px-4  md:py-3 text-sm md:text-2xl rounded-full bg-[#004168] hover:bg-[#006BA9]  mgbut">
  				<Link to="/Nosotros">Saber más</Link>
  				</button>
  			</div>	
		</div>

		<div className="grid grid-cols-8  ">
  			<div className="grid grid-rows-3 col-span-2">
	  			<div className="row-span-2 m-auto">
		  			<div className="col-span-2 text-center m-auto font-primary font-bold text-md md:text-3xl text-[#004168] textot font-primary">Showroom </div>
		  			<div className="col-span-2 text-center m-auto font-primary font-bold text-md md:text-3xl text-[#004168] textot md:mt-4 font-primary">Victoria</div>
		  			<div className=" flex flex-col items-center md:mt-4">
		  				<button class="bg-[#004168] hover:bg-[#006BA9] text-white mt-1 md:mt-4 py-1 md:py-3 px-2 font-primary md:px-4 rounded-full textob">
	  					<Link to="/Showrooms">Descubre más</Link>
						</button>
					</div>
	  			</div>
	  			<div className="grid grid-cols-2 ">
	  				<div className=" col-span-1 bg-[#0BA2FB] "></div>
	  				<div className=" col-span-1 bg-[#FFBB00] "></div>
	  			</div>
	  		</div>

  			<div className="col-span-2">
  				<img  src={vic} alt="Grupo Selca" className="w-full"/>
  			</div>

  			<div class="grid grid-rows-3 col-span-2">
  				<div className="grid grid-cols-2 ">
	  				<div className=" col-span-1 bg-white "></div>
	  				<div className=" col-span-1 bg-[#006BA9] "></div>
	  			</div>
	  			<div className="row-span-2 m-auto">
		  			<div className="col-span-2 text-center m-auto font-primary font-bold text-md md:text-3xl text-[#004168] textot ">
		  			<p className="leading-[1rem] md:leading-[3rem]  px-2">¿Quieres más información?</p> </div>
		  			
		  			<div className=" flex flex-col items-center md:mt-4">
		  				<button class="bg-[#004168] hover:bg-[#006BA9] text-white mt-1 md:mt-3 py-1 md:py-3 px-2 font-primary md:px-4 rounded-full textob">
	  					<Link to="/Contacto">¡Pídela aquí!</Link>
						</button>
					</div>
	  			</div>
  			</div>
  			<div className="col-span-2">
  				<img  src={info} alt="Haz un pedido" className="w-full"/>
  			</div>
 
</div>
			

  		
		

			
		
	 </div>			

  );
};

export default Inicio;

 