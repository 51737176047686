import React from "react"
import FormContacto from "./FormContacto"

const Contacto=()=>{
	
	return (
	 <div>
		<div className=" grid grid-cols-12  ">
			<div className=" col-span-2  bg-white h-10 md:h-[4.4rem]"> </div>
  		  	<div className=" col-span-1 ml-auto w-1/2 bg-[#004168]  h-9.5 md:h-[4.5rem]"> </div>
  		  	<div className=" col-span-2  bg-white h-10 md:h-[4.4rem]"> </div>
  		  	<div className=" col-span-2  ml-auto w-4/5 bg-[#006BA9]  h-9.5 md:h-[4.5rem]"> </div>
  		  	<div className=" col-span-2  bg-white h-10 md:h-[4.4rem]"> </div>
  		  	<div className=" col-span-2  ml-auto w-4/5 bg-[#0BA2FB]   h-9.5 md:h-[4.5rem] "> </div>
  		  	<div className=" col-span-1   bg-white h-10 md:h-[4.4rem]"> </div>
		</div>
		<div className=" grid grid-cols-12  ">
			<div className=" col-span-1    bg-[#0BA2FB]  h-9.5 md:h-[4.5rem]"> </div>
  		  	<div className=" col-span-8    bg-white h-9.5 md:h-[4.4rem]"> </div>
  		  	<div className=" col-span-2    ml-auto w-4/5 bg-[#0BA2FB]  h-7 md:h-[4.5rem] "> </div>
  		  	<div className=" col-span-1    bg-white h-10 md:h-[4.4rem]"> </div>
		</div>

		<div className=" grid grid-cols-12  ">
			<div className=" col-span-1 md:col-span-1   bg-[#0BA2FB]  h-7 md:h-[4.5rem]"> </div>
  		  	<div className=" col-span-9 md:col-span-10 bg-white h-10 md:h-[4.4rem] ml-12">
  		  		<p className="font-primary text-lg text-[#004168] -ml-10 md:ml-0">¿Quieres recibir más información o pedir algún producto?</p>
  		  		<p className="font-bold text-2xl text-[#004168] -ml-10 md:ml-0">¡Contáctanos!</p>
  		  	</div>
  		  	<div className=" col-span-2 md:col-span-1  bg-[#004168] h-9.5 md:h-[4.4rem]"> </div>
		</div>
		
		<div className="md:-ml-28 mt-8 md:-mt-4  ">
			<FormContacto/>
		</div>

		<div className=" grid grid-cols-12  ">	
			<div className=" col-span-1 mr-auto w-4/5 bg-white  h-7 md:h-[4.4rem] "> </div>
  		  	<div className=" col-span-2 bg-[#006BA9] h-10 md:h-[4.4rem]"> 	</div>  
  		  	<div className=" col-span-3 bg-white  h-7 md:h-[4.4rem] "> </div>	
  		  	<div className=" col-span-2 ml-auto w-4/5 bg-[#0BA2FB] h-10 md:h-[4.4rem]"> 	</div>  
  		  	<div className=" col-span-2 mr-auto w-4/5 bg-[#FFBB00]  h-10 md:h-[4.4rem]"> 	</div> 
  		  	<div className=" col-span-1 mr-auto w-4/5 bg-white  h-7 md:h-[4.4rem] "> </div>
  		  	<div className=" col-span-1 mr-auto w-4/5 bg-[#006BA9]  h-7 md:h-[4.4rem] "> </div> 
		</div>
		
	 </div>			

  );
};

export default Contacto;

 