import React from "react";
import CarruselBS from "./CarruselBS.jsx"
import "./estilobs.css";

const BestSellers=()=>{
	return (


	  <div >
	  	<div className=" grid grid-cols-12  ">
			
  		  	<div className="col-span-6 bg-white h-10 md:h-[4.4rem]"> 
  		  	
  		  	</div>
  		  	<div className=" col-span-2 mr-auto w-4/5  bg-[#006BA9]   h-10 md:h-[4.4rem] "> </div>
  		  	<div className=" col-span-4  bg-white h-10 md:h-[4.4rem]"> </div>
		</div>

		<div className=" grid grid-cols-12  ">
			<div className=" col-span-1  bg-white h-10 md:h-[4.4rem]"> </div>
  		  	<div className=" col-span-10">
  		  		<h1 className="font-primary font-bold text-lg md:text-5xl text-[#004168] mt-3 md:mt-0 ml-12 "> Best Sellers
  		  		</h1>
  		  	</div>
  		  		
  		  	<div className=" col-span-1  bg-[#004168] h-10 md:h-[4.4rem]"> </div>
		</div>

		<div className=" mt-12 md:mt-0 grid grid-cols-12  ">
			<div className=" col-span-1  ">
				<div className=" grid grid-rows-12  ">
					<div className="row-span-3  bg-[#0BA2FB]  h-14 md:h-[7.48rem]"></div>
					<div className="row-span-3 mr-auto w-4/5 bg-white  h-12 md:h-[7rem]"></div>
					<div className="row-span-3 mr-auto w-3/6 bg-[#FFBB00]   h-10 md:h-[4.5rem]"></div>
					<div className="row-span-1 ml-auto w-3/6 bg-white   h-12 md:h-[7.2rem]"></div>
					<div className="row-span-2  ps  bg-[#004168]   h-14 md:h-[4.8rem]"></div>
				</div>
			</div>
  		  	<div className=" col-span-10   ml-10 -mt-2 md:m-0">
  		  	<CarruselBS/>
  		  	 </div> 		  	
  		  	
  		  	<div className=" col-span-1  bg-[#004168] h-10 md:h-[4.4rem]"> 
  		  		<div className=" col-span-1 ">
				<div className=" grid grid-rows-12  ">
					<div className="row-span-3  bg-[#0BA2FB]  h-14 md:h-[7.48rem]"></div>
					<div className="row-span-3 mr-auto w-4/5 bg-white  h-12 md:h-[7rem]"></div>
					<div className="row-span-3 ml-auto w-3/6 bg-[#FFBB00]   h-10 md:h-[4.5rem]"></div>
					<div className="row-span-1 ml-auto w-3/6 bg-white   h-12 md:h-[7.2rem]"></div>
					<div className="row-span-2  ps  bg-[#004168]   h-14 md:h-[4.8rem]"></div>
				</div>
			</div>
  		  	</div>
		</div>

		<div className=" grid grid-cols-12 -mt-6 mb-2">	
			<div className=" col-span-1  bg-white  h-10 md:h-[4.4rem] "> </div>
  		  	<div className=" col-span-2 bg-[#004168] h-10 md:h-[4.4rem]"> 	</div>  
  		  	<div className=" col-span-3 bg-white  h-7 md:h-[4.4rem] "> </div>	
  		  	<div className=" col-span-2 ml-auto w-4/6 bg-[#0BA2FB] h-10 md:h-[4.4rem]"> 	</div>  
  		  	<div className=" col-span-2 mr-auto w-4/5 bg-white h-10 md:h-[4.4rem]"> 	</div> 
  		  	<div className=" col-span-2 ml-auto w-4/5 bg-[#FFBB00]  h-10 md:h-[4.4rem] "> </div> 
		</div>
    </div>		

  );
};

export default BestSellers;

