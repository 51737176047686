import cat1 from "../assets/catalogosport/AKSI.jpg";
import cat2 from "../assets/catalogosport/AR7LITE.png";
import cat3 from "../assets/catalogosport/Auro.jpg";
import cat4 from "../assets/catalogosport/Caluxx.jpg";
import cat5 from "../assets/catalogosport/Construlita.jpg";
import cat6 from "../assets/catalogosport/Coolfan.png";
import cat7 from "../assets/catalogosport/Eglo.jpg";
import cat9 from "../assets/catalogosport/EstevezAlpha.jpg";
import cat10 from "../assets/catalogosport/EstevezCarandini.png";
import cat11 from "../assets/catalogosport/EstevezGeneral.jpg";
import cat12 from "../assets/catalogosport/EstevezCoolfan.jpg";
import cat13 from "../assets/catalogosport/EstevezExtractores.png";
import cat14 from "../assets/catalogosport/esteveziluminacion2023.jpg";
import cat15 from "../assets/catalogosport/ESTEVEZA4.jpg";
import cat36 from "../assets/catalogosport/LEDSC4.jpg";
import cat16 from "../assets/catalogosport/EstevezStep.png";
import cat17 from "../assets/catalogosport/Esteveztimbres.png";
import cat18 from "../assets/catalogosport/illux.jpg";
import cat19 from "../assets/catalogosport/collectionILLUX.jpg";
import cat20 from "../assets/catalogosport/Integra.png";
import cat21 from "../assets/catalogosport/LUTRONAtenuadores.png";
import cat22 from "../assets/catalogosport/LUTRONRA2.png";
import cat23 from "../assets/catalogosport/LUTRONRadio.png";
import cat24 from "../assets/catalogosport/LUTRONVive.png";
import cat25 from "../assets/catalogosport/LUXICA.jpg";
import cat26 from "../assets/catalogosport/MAGG.jpg";
import cat27 from "../assets/catalogosport/MAXXI.jpg";
import cat28 from "../assets/catalogosport/NiessenUnno.jpg";
import cat29 from "../assets/catalogosport/NiessenZenit.png";
import cat30 from "../assets/catalogosport/Niessen_Zenit.jpg";
import cat31 from "../assets/catalogosport/VIMARArké.png";
import cat32 from "../assets/catalogosport/VIMAREikon.png";
import cat33 from "../assets/catalogosport/VIMARNeveUp.png";
import cat34 from "../assets/catalogosport/VIMARPlana.png";
import cat35 from "../assets/catalogosport/VIMARSmart.png";
import cat37 from "../assets/catalogosport/Caseta.jpg";
import cat38 from "../assets/catalogosport/QUOR.png";
import cat39 from "../assets/catalogosport/TECNOLITE.jpg";
import catp1  from "../assets/pdf/AKSI.pdf";
import catp2  from "../assets/pdf/AR7LITE.pdf";
import catp3  from "../assets/pdf/Auro.pdf";
import catp4  from "../assets/pdf/Caluxx.pdf";
import catp5  from "../assets/pdf/Construlita.pdf";
import catp6  from "../assets/pdf/Coolfan.pdf";
import catp7  from "../assets/pdf/Eglo.pdf";
import catp9  from "../assets/pdf/EstevezAlpha.pdf";
import catp10 from "../assets/pdf/EstevezCarandini.pdf";
import catp11 from "../assets/pdf/EstevezGeneral.pdf";
import catp12 from "../assets/pdf/EstevezCoolfan.pdf";
import catp13 from "../assets/pdf/EstevezExtractores.pdf";
import catp14 from "../assets/pdf/esteveziluminacion2023.pdf";
import catp15 from "../assets/pdf/ESTEVEZA4.pdf";
import catp36 from "../assets/pdf/LEDSC4.pdf";
import catp16 from "../assets/pdf/EstevezStep.pdf";
import catp17 from "../assets/pdf/Esteveztimbres.pdf";
import catp18 from "../assets/pdf/illux.pdf";
import catp19 from "../assets/pdf/collectionILLUX.pdf";
import catp20 from "../assets/pdf/Integra.pdf";
import catp21 from "../assets/pdf/LUTRONAtenuadores.pdf";
import catp37 from "../assets/pdf/Caseta.pdf";
import catp22 from "../assets/pdf/LUTRONRA2.pdf";
import catp23 from "../assets/pdf/LUTRONRadio.pdf";
import catp24 from "../assets/pdf/LUTRONVive.pdf";
import catp25 from "../assets/pdf/LUXICA.pdf";
import catp26 from "../assets/pdf/MAGG.pdf";
import catp27 from "../assets/pdf/MAXXI.pdf";
import catp28 from "../assets/pdf/NiessenUnno.pdf";
import catp29 from "../assets/pdf/NiessenZenit.pdf";
import catp30 from "../assets/pdf/Niessen_Zenit.pdf";
import catp31 from "../assets/pdf/VIMARArké.pdf";
import catp32 from "../assets/pdf/VIMAREikon.pdf";
import catp33 from "../assets/pdf/VIMARNeveUp.pdf";
import catp34 from "../assets/pdf/VIMARPlana.pdf";
import catp35 from "../assets/pdf/VIMARSmart.pdf";
import catp38 from "../assets/pdf/QUOR.pdf";
import catp39 from "../assets/pdf/TECNOLITE.pdf";

export const itemcatalogo= [
  
  {    id:1, url:      cat1,    alt:"Catálogo AKSI",          ref:catp1,  catg: 'Apagadores'  },
  {    id:2, url:      cat2,    alt:"Catálogo AR7LITE",       ref:catp2,  catg: 'Productos LED'  },
  {    id:3, url:      cat3,    alt:"Catálogo Auro",          ref:catp3,  catg: 'Iluminación Exterior'  },
  {    id:4, url:      cat4,    alt:"Catálogo Caluxx",        ref:catp4,  catg: 'Lámparas de piso'    },
  {    id:5, url:      cat5,    alt:"Catálogo Construlita",   ref:catp5,  catg: 'Línea Comercial'   },
  {    id:6, url:      cat6,    alt:"Catálogo Coolfan",       ref:catp6,  catg: 'Lámparas con ventiladores'    },
  {    id:7, url:      cat7,    alt:"Catálogo Eglo",          ref:catp7,  catg: 'Iluminación de baños' },
  {    id:9, url:      cat9,    alt:"Catálogo EstevezAlpha",  ref:catp9,  catg: 'Apagadores'  },
  {    id:10, url:     cat10,   alt:"Catálogo EstevezCarandini", ref:catp10,  catg: 'Apagadores'  },
  {    id:11, url:     cat11,   alt:"Catálogo EstevezGeneral", ref:catp11,  catg: 'Apagadores'  },
  {    id:12, url:     cat12,   alt:"Catálogo EstevezCoolfan", ref:catp12,  catg: 'Apagadores'  },
  {    id:13,url:      cat13,   alt:"Catálogo EstevezExtractores", ref:catp13, catg: 'Extractores'   },
  {    id:14,url:      cat14,   alt:"Catálogo esteveziluminacion2023", ref:catp14, catg: 'Ilulminación Interior'    },
  {    id:15,url:      cat15,   alt:"Catálogo ESTEVEZA4",        ref:catp15, catg: 'Línea Industrial'    }, 
  {    id:16,url:      cat36,   alt:"Catálogo ESTEVEZLEDSC4",        ref:catp36, catg: 'Línea Industrial'    }, 
  {    id:17,url:      cat16,   alt:"Catálogo EstevezStep",   ref:catp16, catg: 'Extractores'   },
  {    id:18,url:      cat17,   alt:"Catálogo Esteveztimbres", ref:catp17, catg: 'Extractores'   },
  {    id:19,url:      cat18,   alt:"Catálogo illux",         ref:catp18, catg: 'Iluminación Exterior'   },
  {    id:20,url:      cat19,   alt:"Catálogo collectionILLUX", ref:catp19,  catg: 'Sistemas Inteligentes'    },
  {    id:21,url:      cat20,   alt:"Catálogo Integra", ref:catp20, catg: 'Iluminación interior'    },
  {    id:22,url:      cat21,   alt:"Catálogo LUTRONAtenuadores", ref:catp21, catg: 'Iluminación interior'    },
  {    id:23,url:      cat37,   alt:"Catálogo Caseta", ref:catp37, catg: 'Iluminación interior'    },
  {    id:24,url:      cat22,   alt:"Catálogo LUTRONRA2", ref:catp22, catg: 'Iluminación interior'    },
  {    id:25,url:      cat23,   alt:"Catálogo LUTRONRadio", ref:catp23, catg: 'Iluminación interior'    },
  {    id:26,url:      cat24,   alt:"Catálogo LUTRONVive", ref:catp24, catg: 'Iluminación interior'    },
  {    id:27,url:      cat25,   alt:"Catálogo LUXICA", ref:catp25, catg: 'Lámparas colgantes'  },
  {    id:28,url:      cat26,   alt:"Catálogo MAGG", ref:catp26, catg: 'Línea Comercial'   },
  {    id:29,url:      cat27,   alt:"Catálogo MAXXI", ref:catp27, catg: 'Línea Comercial'  },
  {    id:30,url:      cat28,   alt:"Catálogo NiessenUnno", ref:catp28, catg: 'Iluminación interior'    },
  {    id:31,url:      cat29,   alt:"Catálogo NiessenZenit", ref:catp29, catg: 'Accesorios'    },  
  {    id:32,url:      cat30,   alt:"Catálogo Niessen_Zenit", ref:catp30, catg: 'Accesorios'    },
  {    id:33,url:      cat38,   alt:"Catálogo QUOR", ref:catp38, catg: 'Accesorios'    },
  {    id:34,url:      cat39,   alt:"Catálogo TECNOLITE", ref:catp39, catg: 'Accesorios'    },
  {    id:35,url:      cat31,   alt:"Catálogo VIMARArké", ref:catp31, catg: 'Iluminación interior'   },
  {    id:36,url:      cat32,   alt:"Catálogo VIMAREikon", ref:catp32,  catg: 'Lámparas de muro'    },
  {    id:37,url:      cat33,   alt:"Catálogo VIMARNeveUp", ref:catp33,  catg: 'Iluminación interior' },
  {    id:38,url:      cat34,   alt:"Catálogo VIMARPlana", ref:catp34,  catg: 'Iluminación interior' },
  {    id:39,url:      cat35,   alt:"Catálogo VIMARSmart", ref:catp35,  catg: 'Iluminación interior' },
  

  // {   id:114,url:      cat14,  alt:"Catálogo illux2", ref:catp14, catg: 'Iluminación interior'   },
  // {   id:200,url:      cat20,  alt:"Catálogo MEGAMEX2022-2", ref:catp20, catg: 'Iluminación exterior'    },
  // {   id:220,url:      cat22,  alt:"Catálogo TECNOLITE2022-2", ref:catp22, catg: 'Lámparas colgantes'   },
];

export const buttons = [
  {
    nombre: "Todos",    value: "Todos"
  },
  {
    nombre: "Apagadores",    value: "Apagadores"
  },
  {
    nombre: "Productos LED",    value: "Productos LED"
  },
  {
    nombre: "Iluminación Exterior",    value: "Iluminación Exterior"
  },
  {
    nombre: "Lámparas de piso",    value: "Lámparas de piso"
  },
  {
    nombre: "Empotrados de Techo",    value: "Empotrados de Techo"
  },
  {
    nombre: "Línea Comercial",    value: "Línea Comercial"
  },
  {
    nombre: "Lámparas con ventiladores",    value: "Lámparas con ventiladores"
  },
  {
    nombre: "Iluminación de baños",    value: "Iluminación de baños"
  },
  {
    nombre: "Extractores",    value: "Extractores"
  },
  
  {
    nombre: "Accesorios",    value: "Accesorios"
  },
  {
    nombre: "Iluminación Exterior",    value: "Iluminación Interior"
  },
  {
    nombre: "Lámparas de muro",    value: "Lámparas colgantes"
  },
  {
    nombre: "Lámparas de muro",    value: "Lámparas de muro"
  },
  {
    nombre: "Sistemas Inteligentes",    value: "Sistemas Inteligentes"
  },
  
];


