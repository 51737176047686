import React, {useState,useEffect} from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./catalogo.css"
import {  itemcatalogo } from "./datos.jsx";

export default function Catalogos() {

const [getcatalogo, filtrocatalogo] = useState(null);

useEffect(() => {
    filtrocatalogo(getCatalogo());
  }, []);

/*function handleCatalogo(e) {
    let tipocatalogo = e.target.value;
    tipocatalogo !== "Todos"
      ? filtrocatalogo(filterCatalogo(tipocatalogo))
      : filtrocatalogo(getCatalogo());
  }*/

function getCatalogo() {
  const listacatalogo = itemcatalogo;
  return listacatalogo;
}

function filterCatalogo(catType) {
  let filtredCatalogo = getCatalogo().filter(type => type.catg === catType);
  return filtredCatalogo;
}

const settings = {     
      dots: true,
      autoplay: false,
      autoplaySpeed: 2000,
      infinite: filterCatalogo.length > 10,
      slidesToShow: 3.4,
      slidesToScroll: 3.4,
      speed: 1000,
      rows: 2,
      slidesPerRow: 2,
      centermode:true,
      centerPadding: 30,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: .96,
            slidesToScroll: .97,
            dots: false
          }
        }
      ]

    };
	return (
	 <div>
		
		<div className=" grid grid-cols-12  ">		
  		  	<div className=" col-span-3 bg-white h-7 md:h-[4rem]"> 
  		  		<h1 className="font-primary font-bold text-md md:text-5xl text-[#004168] ml-9 md:ml-20 mt-3 md:mt-8 ">Catálogos  </h1>
  		  	</div>
  		  	<div className=" col-span-2 ml-auto w-3/5 md:w-4/5 bg-[#006BA9]  h-10 md:h-[3.3rem] "> </div>
  		  	<div className=" col-span-2 bg-white h-10 md:h-[3.3rem]"> </div>
  		  	<div className=" col-span-1 ml-auto w-4/5 bg-[#004168]  h-10 md:h-[3.3rem] "> </div>
  		  	<div className=" col-span-1  bg-white h-10 md:h-[3.3rem]"> </div>
  		  	<div className=" col-span-2 ml-auto w-4/5 bg-[#0BA2FB]  h-10 md:h-[3.3rem] "> </div>
  		  	<div className=" col-span-1  bg-white h-10 md:h-[3.3rem]"> </div>
		</div>

		{/*<div className="ml-20 mt-3 md:mt-2 ">	
	  		<select data-te-select-init onClick={handleCatalogo}
	  		className="cursor-pointer bg-gray-50 font-primary  -ml-11 md:ml-0 border border-gray-300 text-[#004168] text-sm rounded-lg  block  p-2.5 ">
	  			<label>¿Qué catálogo de producto buscas?</label>
	  			<option className="cursor-pointer" value="Todos" >Todos</option>
	  			<option  type="checkbox" value="Apagadores" >Apagadores</option>
	  			<option value="Accesorios" >Accesorios</option>
	  			<option value="Productos LED" >Productos LED</option>
          <option value="Iluminación Exterior" >Iluminación Exterior</option>
          <option value="Lámparas de piso" >Lámparas de piso</option>
          <option value="Lámparas de muro" >Lámparas de muro</option>
          <option value="Empotrados de Techo" >Empotrados de Techo</option>
          <option value="Sistemas Inteligentes" >Sistemas Inteligentes</option>
          <option value="Línea Comercial" >Línea Comercial</option>
          <option value="Productos LED" >Lámparas con ventiladores</option>
          <option value="Iluminación Exterior" >Iluminación de baños</option>
          <option value="Lámparas de piso" >Extractores</option>
          <option value="Lámparas de muro" >Iluminación Interior</option>
          <option value="Empotrados de Techo" >Lámparas colgantes</option>
          
			</select>
		</div>*/}
		
		<div className="cuerpocatacar " >
			<div className="containercatacar ">
      			<Slider {...settings}>
          {getcatalogo && getcatalogo.map(item => (
            		<div key={item.id} className=" imagencata">
                <a href={item.ref} target="_blank" rel="noopener noreferrer" className=" text-white  " >
              			<img src={item.url} alt={item.alt} width="100%" height="100%" className=" scale-[.82] md:scale-[.8] rounded-md " />
                    </a>
            		</div>
          			))}
        		</Slider>
        	</div>
        </div>
  		 
  		 <div className=" grid grid-cols-12 mt-4 ">		
  		  	<div className=" col-span-1 bg-[#004168] w-4/5 mr-auto h-10 md:h-[3.3rem]">	</div>
  		  	<div className=" col-span-2 bg-white h-10 md:h-[3.3rem]"> </div>
  		  	<div className=" col-span-2 ml-auto w-4/5 bg-[#006BA9]  h-10 md:h-[3.3rem] "> </div>
  		  	<div className=" col-span-2 bg-white h-7 md:h-[3.3rem]"> </div>
  		  	<div className=" col-span-1 ml-auto w-4/5 bg-[#004168]  h-10 md:h-[3.3rem] "> </div>
  		  	<div className=" col-span-2  bg-white h-7 md:h-[3.3rem]"> </div>
  		  	
  		  	<div className=" col-span-2  bg-[#FFBB00] h-10 md:h-[3.3rem]"> </div>
		</div>
  				
		
		
	 	</div>
  );
}


 