import React,{ useRef } from "react"
import emailjs from '@emailjs/browser';



const FormContacto=()=>{
	
   const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bfvdsna', 'template_dj6x7uf', form.current, '_BQGTPDNsstbYeMTr')
      .then((result) => {
          window.location.href = "https://selcailuminacion.com.mx/Aviso";
      }, (error) => {
          console.log(error.text);
      });
      
      e.target.reset();
  };

	return (
		<div>
    {/*<AiFillCloseCircle/>*/}
			<form ref={form} onSubmit={sendEmail} >	 
          <div className="py-4 bg-white flex items-center justify-center">
            <div className="container max-w-screen-lg mx-auto">
              <div className="bg-white rounded-xl shadow-lg p-4 px-12 md:px-4  ">
                
                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                  <div className="lg:col-span-2 ">
                    <div className="  grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                      
                      <div className="md:col-span-6 ">
                        <div className="flex flex-row"><p className=" text-[#004168] text-lg font-primary mr-4 mt-3">Nombre*: </p>
                          <input type="text" name="nombre" id="nombre" placeholder="________________________________________________" required className=" rounded-l-lg text-lg h-10  mt-1 rounded px-4 w-full bg-gray-50" />
                        </div>
                      </div>

                      <div className="md:col-span-6 ">
                        <div className="flex flex-row"><p className="text-[#004168] font-primary text-lg mr-1 mt-3 ">Teléfono (10 dígitos)*: </p>
                        <input type="tel" name="telefono" pattern="[0-9]{10}" id="telefono" className="h-10  mt-1 rounded px-4 w-8/12 bg-gray-50 text-lg" 
                        required  placeholder="________________________________________" />
                           
                        </div>
                      </div>

                      <div className="md:col-span-6 ">
                          <div className="flex flex-row"><p className="text-[#004168] font-primary text-lg mr-4 mt-3 ">Correo*: </p>
                            <input type="email" name="email" id="email" className="h-10  mt-1 rounded px-4 w-full bg-gray-50 text-lg" required  placeholder="_________________________________________________" />
                          </div>
                      </div>

                      {/*<div className="md:col-span-6  mt-2">
                          <div className="flex flex-row">
                            <select name="solicitud" className="md:h-10  text-[#004168] border mt-1 rounded md:px-4 w-6/12 bg-gray-50 text-sm md:text-lg rounded focus:outline-none focus:bg-white focus:border-[#006BA9]" id="grid-state">
                              <option selected className="text-gray ">Elige una opción</option>
                              <option>Visitar Sucursal Victoria</option>
                              <option>Envío de producto</option>
                            </select>
                          </div>
                      </div>*/}

                      

            </div>
          </div>
              <div className="lg:col-span-1 md:-ml-16 md:ml-4 mt-2">
                      <label class="col-span-6 mb-6 ">
                  <span className="font-primary text-lg  text-[#004168]">Mensaje*</span>
                  <textarea
                    name="mensaje"
                    className="
                      block
                      w-full
                      mt-1
                      border
                      border-[#004168]
                      rounded-md
                      shadow-sm
                      focus:border-indigo-300
                      focus:ring
                      focus:ring-indigo-200
                      focus:ring-opacity-50
                    "
                    rows="8"
                    placeholder=""
                    required
                  ></textarea>
                </label>
              </div>
      
                      <div className="md:col-span-5 text-right mr-4 mt-2">
                        <div className="inline-flex items-center  ">
                          <button id="cotización" type="submit" className="bg-[#006BA9]  hover:bg-[#004168] text-white text-md font-primary font-bold py-2 px-4 rounded" value="Send">Enviar</button>
                        </div>
                      </div>
        </div>
      

      </div>  

  </div>

</div>
</form>

			
	</div>			
  );
};

export default FormContacto;

 