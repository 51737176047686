import React/*, {useState}}*/ from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./estilobs.css";

const Images  = [
  {
    id: 1,
    src: require("../assets/LOOP.png"),
    alt: "Lámpara LOOP",
  
  },
  {
    id: 2,
    src: require("../assets/PAVLOVA.png"),
    alt: "Lámpara PAVLOVA ",
  },
  {
    id: 3,
    src: require("../assets/SAKURA.png"),
    alt: "Lámpara SAKURA",
    
  },
  {
    id: 4,
    src: require("../assets/SELMA.png"),
    alt: "Lámpara SELMA",
    
  },
  {
    id: 5,
    src: require("../assets/NEO.png"),
    alt: "Lámpara NEO",
    /*title: "Ferrari 458 Speciale",
    description:
      "0 to 100 km/h (0 to 62 mph) takes 3.0 seconds and the Spider is capable of a top speed of 400 km/h (249 mph).",*/
  },
  
];

const settings = {
  dots: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 3000,
  variableWidth: false,
  responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: .955,
            slidesToScroll: 1,
            dots: true,
            infinite:true
          }
        }
      ]
};


const CarruselBS=()=>{
	
	return (

	  <div className=" cuerpobs contentbs mt-6 md:-mt-6 mb-4">

      <div className="containerbs" >
        <Slider {...settings}>
          {Images.map((item) => (
            <div key={item.id}>
              <img src={item.src} alt={item.alt} width="100%" height="100%"  className="scale-[.8] scale-[.95] md:ml-16 imagbs" />
              
              
            </div>
          ))}
        </Slider>
      </div>
      
    </div>		

  );
};

export default CarruselBS;

 