import React from "react"
import nosotros from "../assets/fondonos.jpg";
import './nosotros.css'
const Nosotros=()=>{
	
	return (
	 <div >
		<div className=" grid grid-cols-11 containernos  ">
		
 		<div className=" col-span-9 md:col-span-7 w-10/12 md:w-10/12 ml-14 md:ml-20 text-justify part1  "> 
 			<div className="grid grid-rows-8 ">
 			  <div className="row-span-1 w-3/12 md:w-2/12 ml-auto mr-6 md:mr-24 bg-[#0BA2FB]  h-8 md:h-[4.4rem]"></div>
 			  <div className="row-span-6 md:ml-6 md:mr-4">
	  		    <h1 className="font-primary font-bold text-2xl md:text-5xl text-[#004168] md:mb-8 -mt-8 md:mt-8"> Nosotros </h1>
	  		    <p className="font-primary mt-2 md:mt-20 mb-2 md:mb-4 text-sm md:text-xl text-[#004168]"> Somos un grupo con más de 30 años iluminando vidas y queremos hacerlo con la tuya; desde un foco,
	  		     hasta la iluminación completa para tu hogar.</p>
				<p className="font-primary mb-2 md:mb-14 text-sm md:text-xl text-[#004168]">Además, ayudamos a arquitectos, diseñadores, negocios e instituciones, a que hagan realidad sus proyectos.
				Contamos con un Outlet con precios aún más increíbles y envíos a toda la República.</p>
				{/*<div className="grid grid-cols-11 md:grid-cols-7" >
					<p className="col-span-9 md:col-span-5 font-primary text-sm md:text-xl text-[#004168]">Nuestros productos también los encuentras en sucursales</p>
				 	<img   src={logoilted} alt="Logo Ilted" className=" col-span-1 mt-[25px] -ml-[39px] md:ml-0 md:-mt-4 scale-[1.5] md:scale-[.9]"/>
				 	<p className="col-span-1 font-primary text-sm md:text-xl text-[#004168] -ml-1">,</p>
				</div>

				<div className="grid grid-cols-6 md:grid-cols-7" >
					<p className="col-span-3 md:col-span-4 font-primary text-sm md:text-xl text-[#004168] mt-[3.6px] md:mt-[11.7px]">una marca de</p>
				 	<img   src={logoselca} alt="Logo Ilted" className=" col-span-2  -ml-[40px] md:-mt-2 md:-ml-[333px] md:scale-[.8]"/>
				 	<p className="col-span-1 font-primary text-sm md:text-xl text-[#004168] mt-1 -ml-[43px] md:-ml-[357px] md:mt-[11px]">.</p>
				 </div>
			*/}
				
				   
  		  		
  		  		</div>
  		  		<div className="row-span-1 ">
  		  			<div className=" grid grid-cols-11  ">
  		  				<div className=" col-span-2 md:col-span-1 bg-[#006BA9] mt-1 md:mt-[114px] h-7 md:h-[4.5rem]"> </div>
  		  				<div className=" col-span-6 md:col-span-8 bg-white h-10 md:h-[4.4rem]"> </div>
  		  				<div className=" col-span-3 md:col-span-2 bg-[#FFBB00] mt-1 md:mt-[114px]  h-7 md:h-[4.5rem] "> </div>
  		  			</div>
  		  		</div>

  		  	</div>
  		 </div>

  		<div className=" ml-14 md:ml-0 col-span-9 md:col-span-4 part2 ">
  		    <img  src={nosotros} alt="Nosotros" className="w-full h-full   "/>
  		</div>
  			
  			
  					
  			
		</div>
		
	 </div>			

  );
};

export default Nosotros;

 